<template>
  <section class="h-full w-full items-center flex justify-center">
    <button
      id="search-button"
      aria-label="Search Button"
      class="fill-current hover:text-primary transition-all pr-4 pl-5 lg:px-6 lg:pl-6.5 lg:pr-6 search-btn"
      @click="onShowSearch()"
    >
      <icon-search />
    </button>
  
    <search
      v-if="showSearch === true"
      :show-search="showSearch"
      @showSearch="onShowSearch()"
    />
  </section>
</template>
  
<script>
import { defineAsyncComponent } from "vue";
const IconSearch = defineAsyncComponent(() =>
  import("../../../../../../FrontendBundle/assets/js/icons/icons/IconSearch" /* webpackChunkName: "image-search" */)
);
const Search = defineAsyncComponent(() =>
  import("./Search" /* webpackChunkName: "search" */)
);
  
export default {
  name: "NavigationSearch",
  components: {
    IconSearch,
    Search
  },
  model: {
    showSearch: false
  },
  data() {
    return {
      showSearch: false
    };
  },
  methods: {
    onShowSearch() {
      this.open = false;
      this.showSearch = !this.showSearch;
    }
  }
}
</script>